import 'bootstrap';
import 'jquery';
import './dom/header';

/**
 * This will be the main javascript file
 * This should only be used as an entry point
 * for webpack. All scripts shoudl live in
 * their own modules
 */

$(() => {
  console.log('This is the main script');
});
